<!-- LEMBRAR DE LIMPAR VALOR DO AUTOCOMPLETE QUANDO MUDAR DE TIPO DE VINCULO -->

<template>
  <v-card flat>
    <v-data-table
      :headers="headers"
      :items="CompanyBranchAgreements"
      disable-pagination
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            small
            color="success"
            @click.stop="insertDialog = true"
          >
            <v-icon left>mdi-plus</v-icon> Associar novo convênio
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="mr-2"
          title="Remover associação"
          @click="deleteCompanyBranchAgreement(item.AgreementId)"
          small
          icon
        >
          <v-icon>mdi-link-variant-remove</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.fullName`]="{ item }">
        {{ item.Doctor.Person.fullName }}
      </template>
    </v-data-table>

    <v-dialog max-width="600" v-model="insertDialog">
      <CompanyBranchAgreementForm
        dialog
        title="Associar novo convênio a filial"
        mod="insert"
        @close-dialog="insertDialog = false"
        @insert-completed="afterAgreementInserted"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import CompanyBranchAgreementForm from "@/components/CompanyBranchAssociations/CompanyBranchAgreements/CompanyBranchAgreementForm.vue";
import errorHandler from "@/helpers/error_handler";
export default {
  components: { CompanyBranchAgreementForm },
  data: () => ({
    headers: [
      { text: "#ID", value: "id" },
      { text: "Convênio", value: "Agreement.name" },
      { text: "Ações", value: "actions" },
    ],
    loading: false,
    CompanyBranchAgreements: [],
    resultsPerPage: 15,
    totalRows: 0,
    filters: {
      currentPage: 1,
    },
    insertDialog: false,
  }),
  methods: {
    afterAgreementInserted() {
      this.insertDialog = false;
      this.loadCompanyBranchAgreements();
    },
    async loadCompanyBranchAgreements() {
      try {
        this.loading = true;

        const companyBranchId = this.$route.params.id;

        let url = `/company-branches/${companyBranchId}/agreements`;

        const response = await this.$axios.get(url);

        this.setCompanyBranchAgreements(response.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    setCompanyBranchAgreements(data) {
      const cpData = [...data];
      this.CompanyBranchAgreements = cpData;
    },
    async deleteCompanyBranchAgreement(id) {
      try {
        const companyBranchId = this.$route.params.id;

        const confirm = await this.$root.$confirm(
          "Atenção",
          `Deseja realmente deletar o convênio com o id n. ${id} desta filial ?`
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/company-branches/${companyBranchId}/agreements/${id}`;

        await this.$axios.delete(url);

        this.deleteCompanyBranchAgreementFromTable(id);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    deleteCompanyBranchAgreementFromTable(id) {
      const index = this.CompanyBranchAgreements.findIndex(
        (p) => p.DoctorId === id
      );

      this.CompanyBranchAgreements.splice(index, 1);
    },
  },
  created() {
    this.loadCompanyBranchAgreements();
  },
};
</script>

<style>
</style>