<template>
  <v-card flat>
    <v-data-table
      :headers="headers"
      :items="companyBranchProcedures"
      disable-pagination
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            small
            color="success"
            @click.stop="insertDialog = true"
          >
            <v-icon left>mdi-plus</v-icon> Associar novo procedimento
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="mr-2"
          title="Remover associação"
          @click="deleteCompanyBranchProcedure(item.id)"
          small
          icon
        >
          <v-icon>mdi-link-variant-remove</v-icon>
        </v-btn>
        <v-btn
          class="mr-2"
          title="Atualizar preço"
          @click="openPriceModal(item.id)"
          small
          icon
        >
          <v-icon>mdi-currency-usd</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog max-width="600" v-model="insertDialog">
      <CompanyBranchProcedureForm
        dialog
        title="Associar novo procedimento à esta filial"
        mod="insert"
        @close-dialog="insertDialog = false"
        @insert-completed="afterInsert"
      />
    </v-dialog>

    <v-dialog max-width="600" v-model="procedurePriceDialog">
      <CompanyBranchProcedurePriceForm
        dialog
        title="Atribuir novo preço do procedimento à esta filial"
        mod="update"
        :procedureCpBranchId="procedureCpBranchId"
        @close-dialog="procedurePriceDialog = false"
        @updated-price="afterUpdatePrice"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import CompanyBranchProcedureForm from "@/components/CompanyBranchAssociations/CompanyBranchProcedures/CompanyBranchProcedureForm.vue";
import CompanyBranchProcedurePriceForm from "@/components/CompanyBranchAssociations/CompanyBranchProcedures/CompanyBranchProcedurePriceForm.vue";
import errorHandler from "@/helpers/error_handler";

export default {
  components: { CompanyBranchProcedureForm, CompanyBranchProcedurePriceForm },
  data: () => ({
    headers: [
      { text: "#ID", value: "id" },
      { text: "Procedimento", value: "name" },
      { text: "Preço (R$)", value: "formattedPrice" },
      { text: "Ações", value: "actions" },
    ],
    loading: false,
    companyBranchProcedures: [],
    resultsPerPage: 15,
    totalRows: 0,
    filters: {
      currentPage: 1,
    },
    procedureCpBranchId: null,
    procedurePriceToUpdate: null,
    insertDialog: false,
    procedurePriceDialog: false,
  }),
  methods: {
    async loadCompanyBranchProcedure() {
      try {
        this.loading = true;

        const companyBranchId = this.$route.params.id;

        let url = `/company-branches/${companyBranchId}/procedures`;

        const response = await this.$axios.get(url);

        this.setCompanyBranchProcedure(response.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    setCompanyBranchProcedure(data) {
      const cpData = [...data];

      this.companyBranchProcedures = cpData.map((e) => {
        e.price =
          e.BranchProcedurePricings.length > 0
            ? e.BranchProcedurePricings[0].price
            : null;

        e.formattedPrice = e.price
          ? parseFloat(e.price).toFixed(2).replace(".", ",")
          : "-";

        e.name = e.Procedure.name;

        return e;
      });
    },
    async deleteCompanyBranchProcedure(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Atenção",
          `Deseja realmente deletar a associação com o id n. ${id}?`
        );

        if (!confirm) return;

        this.loading = true;

        const companyBranchId = this.$route.params.id;

        let url = `/company-branches/${companyBranchId}/procedures/${id}`;

        await this.$axios.delete(url);

        this.deleteCompanyBranchProcedureFromTable(id);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    deleteCompanyBranchProcedureFromTable(id) {
      const index = this.companyBranchProcedures.findIndex((p) => p.id === id);

      this.companyBranchProcedures.splice(index, 1);
    },
    openPriceModal(id) {
      this.procedureCpBranchId = id;
      this.procedurePriceDialog = true;
    },
    afterInsert() {
      this.insertDialog = false;
      this.loadCompanyBranchProcedure();
    },
    afterUpdatePrice() {
      this.procedurePriceDialog = false;
      this.loadCompanyBranchProcedure();
    },
  },
  created() {
    this.loadCompanyBranchProcedure();
  },
};
</script>

<style></style>
