<template>
  <v-select
    :loading="loading"
    :value="value"
    @change="$emit('input', $event)"
    :items="typeOfLinks"
    :readonly="readonly"
    item-text="name"
    item-value="id"
    label="Tipo de vínculo"
  ></v-select>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  props: ["value", "readonly", "ignoredValues"],
  data: () => ({
    loading: false,
    typeOfLinks: [],
  }),
  methods: {
    async loadTypeOfLinks() {
      try {
        this.loading = true;
        const response = await this.$axios.get(`/type-of-links`);

        this.setTypeOfLinks(response.data);

        this.loading = false;
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      } finally {
        this.loading = false;
      }
    },
    setTypeOfLinks(data) {
      const cpData = [...data];

      const filteredData =
        this.ignoredValues && Array.isArray(this.ignoredValues)
          ? cpData.filter((d) => !this.ignoredValues.includes(d.id))
          : cpData;

      this.typeOfLinks = filteredData;
    },
  },
  created() {
    this.loadTypeOfLinks();
  },
};
</script>

<style>
</style>