<template>
  <div>
    <BaseForm
      :title="title"
      :mod="mod"
      :flat="flat"
      :divider="divider"
      :dialog="dialog"
      :loading="loading"
      @save="save"
      @close-dialog="$emit('close-dialog')"
    >
      <template #content>
        <v-row>
          <v-col>
            <v-select
              :loading="loadingAgreements"
              label="Convênio:"
              v-model="selectedAgreement"
              hide-details
              class="required"
              :items="agreements"
              item-value="id"
              item-text="name"
            ></v-select>
          </v-col>
        </v-row>
      </template>

      <template #actions></template>
    </BaseForm>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Dados atualizados com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeSuccessDialog">Fechar</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";

export default {
  components: {
    BaseForm,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    loadingAgreements: false,
    selectedAgreement: null,
    successDialog: false,
    actionAfterOperation: null,
    agreements: [],
  }),
  watch: {},
  computed: {},
  methods: {
    validate() {
      const errors = [];

      if (!this.selectedAgreement)
        errors.push("O campo CONVÊNIO é obrigatório");

      return errors;
    },

    save(actionAfterOperation) {
      const errors = this.validate();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this.actionAfterOperation = actionAfterOperation;
        this[this.mod]();
      }
    },
    async insert() {
      try {
        this.loading = true;

        const dataToSend = this.prepareDataToSend();

        const companyBranchId = this.$route.params.id;

        await this.$axios.post(
          `/company-branches/${companyBranchId}/agreements`,
          dataToSend
        );

        this.successDialog = true;
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async update() {},
    async loadAgreements() {
      try {
        this.loadingAgreements = true;

        const response = await this.$axios.get(`/agreements`);

        this.setAgreements(response.data);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loadingAgreements = false;
      }
    },
    prepareDataToSend() {
      const form = { AgreementId: this.selectedAgreement };

      return form;
    },
    setAgreements(agreements) {
      this.agreements = [...agreements];
    },
    closeSuccessDialog() {
      if (this.actionAfterOperation === "back") this.$router.go(-1);
      else if (this.actionAfterOperation === "stay") {
        this.successDialog = false;
        this.$emit("insert-completed");
      }
    },
    handleError(error) {
      console.log(error);

      const errorHandled = errorHandler.treatError(error);

      this.$root.$errorDialog(errorHandled);
    },
  },
  created() {
    this.loadAgreements();
  },
};
</script>

<style>
</style>