<template>
  <div>
    <BaseForm
      :title="title"
      :mod="mod"
      :flat="flat"
      :divider="divider"
      :dialog="dialog"
      :loading="loading"
      @save="save"
      @close-dialog="$emit('close-dialog')"
    >
      <template #content>
        <v-row>
          <v-col>
            <TypeOfLinkSelect
              v-model="selectedTypeOfLink"
              :readonly="readOnlyLinkTS"
              :ignoredValues="[3, 5]"
            />
          </v-col>
          <v-col>
            <PeopleAutocomplete
              v-model="selectedPerson"
              :disabled="!selectedTypeOfLink"
              :typeOfLink="selectedTypeOfLink"
            />
          </v-col>
        </v-row>
      </template>

      <template #actions></template>
    </BaseForm>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Dados atualizados com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeSuccessDialog">Fechar</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";
import PeopleAutocomplete from "@/components/template/FormElements/People/PeopleAutocomplete.vue";
import TypeOfLinkSelect from "@/components/template/FormElements/People/TypeOfLinkSelect.vue";

export default {
  components: {
    BaseForm,
    PeopleAutocomplete,
    TypeOfLinkSelect,
  },
  mixins: [formMixin],
  props: ["typeOfLink"],
  data: () => ({
    loading: false,
    selectedTypeOfLink: null,
    selectedPerson: null,
    successDialog: false,
    actionAfterOperation: null,
  }),
  watch: {
    typeOfLink(val) {
      this.selectedTypeOfLink = val.id;
    },
  },
  computed: {
    readOnlyLinkTS() {
      return this.typeOfLink ? true : false;
    },
  },
  methods: {
    validate() {
      const errors = [];

      if (!this.selectedTypeOfLink)
        errors.push("O campo TIPO DE VÍNCULO é obrigatório");

      if (!this.selectedPerson) errors.push("O campo PESSOA é obrigatório");

      return errors;
    },

    save(actionAfterOperation) {
      const errors = this.validate();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this.actionAfterOperation = actionAfterOperation;
        this[this.mod]();
      }
    },
    async insert() {
      try {
        this.loading = true;

        const dataToSend = this.prepareDataToSend();

        const companyBranchId = this.$route.params.id;

        await this.$axios.post(
          `/company-branches/${companyBranchId}/${this.typeOfLink.path}`,
          dataToSend
        );

        this.successDialog = true;
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    prepareDataToSend() {
      const modelName = this.typeOfLink.modelName;

      const specialization = this.selectedPerson[`${modelName}`];

      const form = {};

      form[`${modelName}Id`] = specialization.id;

      return form;
    },
    async update() {},
    closeSuccessDialog() {
      if (this.actionAfterOperation === "back") this.$router.go(-1);
      else if (this.actionAfterOperation === "stay") {
        this.successDialog = false;
        this.$emit("insert-completed");
      }
    },
  },
  created() {
    // console.log(this.typeOfLink);
    this.selectedTypeOfLink = this.typeOfLink.id;
  },
};
</script>

<style>
</style>