<template>
  <v-card>
    <v-toolbar flat>
      <v-app-bar-nav-icon
        ><v-btn title="voltar" icon @click="$router.go(-1)"
          ><v-icon>mdi-keyboard-backspace</v-icon></v-btn
        ></v-app-bar-nav-icon
      >
      <v-card-title>Associações da filial </v-card-title>
    </v-toolbar>

    <v-tabs v-model="tab">
      <v-tab>Colaboradores</v-tab>
      <v-tab>Técnicos</v-tab>
      <v-tab>Médicos</v-tab>
      <v-tab>Procedimentos</v-tab>
      <v-tab>Convênios</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item><CompanyBranchEmployees /></v-tab-item>
      <v-tab-item><CompanyBranchTechnicians /></v-tab-item>
      <v-tab-item><CompanyBranchDoctors /></v-tab-item>
      <v-tab-item><CompanyBranchProcedures /></v-tab-item>
      <v-tab-item><CompanyBranchAgreements /></v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import CompanyBranchProcedures from "@/components/CompanyBranchAssociations/CompanyBranchProcedures/CompanyBranchProcedures.vue";
import CompanyBranchDoctors from "@/components/CompanyBranchAssociations/CompanyBranchPeople/CompanyBranchDoctors.vue";
import CompanyBranchTechnicians from "@/components/CompanyBranchAssociations/CompanyBranchPeople/CompanyBranchTechnicians.vue";
import CompanyBranchEmployees from "@/components/CompanyBranchAssociations/CompanyBranchPeople/CompanyBranchEmployees.vue";
import CompanyBranchAgreements from "@/components/CompanyBranchAssociations/CompanyBranchAgreements/CompanyBranchAgreements.vue";

export default {
  components: {
    CompanyBranchTechnicians,
    CompanyBranchEmployees,
    CompanyBranchDoctors,
    CompanyBranchProcedures,
    CompanyBranchAgreements,
  },
  data: () => ({
    tab: null,
  }),
};
</script>

<style>
</style>